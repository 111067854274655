import makeDirectionsApi from '@academica-box/api/directions'
import makeSubscribesApi from '@academica-box/api/subscribes'
import makeVkConnectApi from '@academica-box/api/VKConnect'
import makeCommonApi from '@academica-box/api/common'
import makeCoursesApi from '@academica-box/api/courses'
import makeReviewsApi from '@academica-box/api/reviews'
import makeGrowthbookApi from '@academica-box/api/growthbook'
import makeRecaptchaApi from '@academica-box/api/recaptcha'
import makeSuggestionsApi from '@academica-box/api/suggestions'
import makePromoApi from '@academica-box/api/promo'
import makePassportApi from '@academica-box/api/passport'
import makeFilterApi from '@/api/filters'
import makePartnersApi from '@/api/partners'
import makeCertificateApi from '@/api/certificate'
import makeMainPageApi from '@/api/mainPage'

interface IApi {
  directions: ReturnType<typeof makeDirectionsApi>,
  courses: ReturnType<typeof makeCoursesApi>,
  filters: ReturnType<typeof makeFilterApi>,
  common: ReturnType<typeof makeCommonApi>,
  partners: ReturnType<typeof makePartnersApi>,
  certificate: ReturnType<typeof makeCertificateApi>,
  reviews: ReturnType<typeof makeReviewsApi>,
  subscribes: ReturnType<typeof makeSubscribesApi>,
  promo: ReturnType<typeof makePromoApi>,
  passport: ReturnType<typeof makePassportApi>,
  vkConnect: ReturnType<typeof makeVkConnectApi>,
  mainPage: ReturnType<typeof makeMainPageApi>,
  growthbook: ReturnType<typeof makeGrowthbookApi>,
  recaptcha: ReturnType<typeof makeRecaptchaApi>,
  suggestions: ReturnType<typeof makeSuggestionsApi>,
}

declare module '#app' {
  interface NuxtApp {
    $api: IApi;
  }
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  return {
    provide: {
      api: {
        directions: makeDirectionsApi(config),
        common: makeCommonApi(config),
        subscribes: makeSubscribesApi(config),
        vkConnect: makeVkConnectApi(config),
        courses: makeCoursesApi(config),
        reviews: makeReviewsApi(config),
        growthbook: makeGrowthbookApi(config),
        recaptcha: makeRecaptchaApi(config),
        suggestions: makeSuggestionsApi(config),
        promo: makePromoApi(config),
        passport: makePassportApi(config),
        filters: makeFilterApi(),
        partners: makePartnersApi(),
        certificate: makeCertificateApi(),
        mainPage: makeMainPageApi(),
      },
    },
  }
})
